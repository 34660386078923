import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TwoCols = makeShortcode("TwoCols");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TwoCols imageCol="/img/6.-la-consigne-greengo-scan-de-l-id.jpg" inverted="true" mdxType="TwoCols">
      <h2><strong parentName="h2">{`PARIS S'EMBALLE : MOINS DE JETABLE, PLUS DE DURABLE`}</strong></h2>
      <p><strong parentName="p">{`Trop d'emballages jetables suite à l'explosion de la vente à emporter ?`}</strong>{` `}<strong parentName="p">{`Une réglementation qui vise à les interdire d'ici 2023 ?`}</strong>{` `}</p>
      <p>{`Les restaurateurs ont besoin d’apporteurs de solution. `}</p>
      <p>{`C’est tout l’intérêt du déploiement du projet Paris s’emballe.  `}</p>
      <p>{`Avec l’accompagnement financier de la Ville de Paris, de CITEO et de l’ADEME, `}<strong parentName="p">{`La Consigne GreenGo propose un accompagnement simple aux restaurateurs pour faciliter leur transition vers un modèle d’emballages réutilisables et consignés.`}</strong></p>
    </TwoCols>
    <TwoCols imageCol="/img/3.-la-consigne-greengo-contenant-reutilisable.jpg" inverted="false" mdxType="TwoCols">
      <p>{`Disponible actuellement chez les restaurateurs (voir `}<a parentName="p" {...{
          "href": "https://www.laconsignegreengo.com/nous-trouver"
        }}>{`cartographie`}</a>{`) et dans une dizaine de Franprix à Paris, La Consigne GreenGo souhaite fédérer l’ensemble de ces acteurs pour `}<strong parentName="p">{`devenir le 1er réseau de restos sans emballage et faciliter le service pour les consommateurs.`}</strong></p>
      <p>{`L'ambition ? Développer ce modèle à l'ensemble de la Ville de Paris et à l'échelle nationale.`}</p>
    </TwoCols>
    <TwoCols imageCol="/img/1.-la-consigne-greengo-telecharger-l_app.jpg" inverted="true" mdxType="TwoCols">
      <h2><strong parentName="h2">{`COMMENT CA MARCHE ?`}</strong></h2>
      <p><strong parentName="p">{`C’est simple et gratuit !`}</strong></p>
      <ol>
        <li parentName="ol">{`Téléchargez l’application La Consigne GreenGo sur `}<a parentName="li" {...{
            "href": "https://play.google.com/store/apps/details?id=fr.mygreengo.greengo"
          }}>{`Google Play`}</a>{` ou sur `}<a parentName="li" {...{
            "href": "https://apps.apple.com/fr/app/greengo/id1440472850"
          }}>{`l'App Store`}</a></li>
        <li parentName="ol">{`Découvrez l’ensemble des partenaires du réseau grâce à la `}<a parentName="li" {...{
            "href": "https://www.laconsignegreengo.com/nous-trouver"
          }}>{`cartographie`}</a></li>
        <li parentName="ol">{`Demandez votre repas dans un emballage consigné `}</li>
        <li parentName="ol">{`Rapportez-le dans un point de collecte sous 1 mois`}</li>
      </ol>
    </TwoCols>
    <p><strong parentName="p">{`VOUS ÊTES RESTAURATEURS ET INTERÉSSÉS ?`}</strong>{` `}</p>
    <p>{`Rejoignez le réseau et contactez Blanche par mail `}<a parentName="p" {...{
        "href": "mailto:blanche@laconsignegreengo.com"
      }}>{`blanche@laconsignegreengo.com`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.140625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "greengo projet mairie 10 final bleu",
          "title": "greengo projet mairie 10 final bleu",
          "src": "/static/0ceb49405c80749e6a4814b039b09128/f1720/greengo_projet-mairie-10_final_bleu.png",
          "srcSet": ["/static/0ceb49405c80749e6a4814b039b09128/85b06/greengo_projet-mairie-10_final_bleu.png 256w", "/static/0ceb49405c80749e6a4814b039b09128/bc282/greengo_projet-mairie-10_final_bleu.png 512w", "/static/0ceb49405c80749e6a4814b039b09128/f1720/greengo_projet-mairie-10_final_bleu.png 1024w", "/static/0ceb49405c80749e6a4814b039b09128/4e07d/greengo_projet-mairie-10_final_bleu.png 1044w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      